import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Tooltip } from 'antd';
import classNames from 'classnames';
import TruncateMarkup from 'react-truncate-markup';

import { releaseTypeToAlt, slugify } from 'helpers/view';

import ArtistLink from './ArtistLink';
import c from './ReleaseCard.less';

const ReleaseCard = (props) => {
  const {
    release,
    releaseLinkPrefix,
    showPrice,
    type,
    showArtist,
    actions,
    bottomInfoContent,
    bottomInfoClassName,
    tracking,
    showGenre,
  } = props;

  const history = useHistory();
  const releaseLink = `/${releaseLinkPrefix}/${slugify(release.name, release.id)}`;

  const handleLinkClick = (e) => {
    e.preventDefault();
    const url = new URL(e.currentTarget.href);

    history.push(url.pathname, tracking);
  };

  return (
    <div className={classNames(c.releaseCard, { [c.light]: type === 'light' })}>
      <a href={releaseLink} onClick={handleLinkClick} className={c.coverLink}>
        <img
          className={c.cover}
          src={`${release.coverUrl}/230.jpg`}
          alt={`${release.name} mp3 ${releaseTypeToAlt(release.type)} by ${release.artist.name}`}
        />
        <div className={c.coverBg} />
      </a>
      <div className={classNames(c.info, { [c.noArtist]: !showArtist })}>
        <Tooltip title={release.name}>
          <h4 className={c.releaseTitle}>
            <TruncateMarkup lines={2}>
              <a href={releaseLink} onClick={handleLinkClick}>{release.name}</a>
            </TruncateMarkup>
          </h4>
        </Tooltip>
        {
          showArtist && (
            <ArtistLink artist={release?.artist} />
          )
        }
        <div className={classNames(c.bottomInfo, bottomInfoClassName)}>
          {
            bottomInfoContent || (
              <ul className={c.bottomInfoContent}>
                <li className={c.year}>{release.year}</li>
                {showGenre ? (
                  <>
                    <li className={c.sep} />
                    <li className={c.genre} title={release.genres.join(', ')}>{release.genres[0]}</li>
                  </>
                ) : null}
              </ul>
            )
          }
          {
            showPrice ? (
              <a href={releaseLink} onClick={handleLinkClick} className={c.price}>
                $<strong>{release.totalPrice.toFixed(2)}</strong>
              </a>
            ) : null
          }
          {
            actions ? (<div className={c.actions}>{actions}</div>) : null
          }
        </div>
      </div>
    </div>
  );
};

ReleaseCard.propTypes = {
  release: PropTypes.object.isRequired,
  showPrice: PropTypes.bool,
  showArtist: PropTypes.bool,
  showGenre: PropTypes.bool,
  type: PropTypes.string,
  actions: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  bottomInfoContent: PropTypes.element,
  bottomInfoClassName: PropTypes.string,
  releaseLinkPrefix: PropTypes.string,
  tracking: PropTypes.object,
};

ReleaseCard.defaultProps = {
  showPrice: true,
  showArtist: true,
  showGenre: false,
  type: '',
  actions: null,
  bottomInfoClassName: '',
  bottomInfoContent: null,
  releaseLinkPrefix: 'release',
  tracking: null,
};

export default memo(ReleaseCard);
