import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Pagination as AntPagination } from 'antd';
import {
  compose,
  prop,
  map,
  flip,
  assoc,
} from 'ramda';
import BackIcon from 'material-design-icons/navigation/svg/production/ic_arrow_back_24px.svg';
import ForwardIcon from 'material-design-icons/navigation/svg/production/ic_arrow_forward_24px.svg';

import { defaultPagination } from './common';

import c from './Pagination.less';

const itemRender = (baseHref) => (page, type, originalElement) => {
  const linkProps = {};

  if (baseHref) {
    if (page === 1) {
      linkProps.to = `${baseHref}`;
    } else {
      linkProps.to = `${baseHref}/${page}`;
    }
  }

  switch (type) {
    case 'prev':
      return <Link {...linkProps}><BackIcon /></Link>;
    case 'next':
      return <Link {...linkProps}><ForwardIcon /></Link>;
    case 'page':
      return <Link {...linkProps}>{page}</Link>;
    case 'jump-prev':
      return <Link {...linkProps} {...originalElement.props} />; // eslint-disable-line
    case 'jump-next':
      return <Link {...linkProps} {...originalElement.props} />; // eslint-disable-line
    default:
      return originalElement;
  }
};

/**
 * @return {null}
 */
function Pagination({
  total,
  isLoading,
  onLoadMore,
  onPageChange,
  currentPage,
  itemsPerRow,
  showSizeChanger,
  pageSize = defaultPagination.maxCols * itemsPerRow,
  showPages,
  showLoadMore,
  showMoreTitle,
  baseHref,
}) {
  const handlePageChange = (page) => {
    const passPage = page === 1 ? null : page;

    return onPageChange(passPage);
  };

  return (
    <div className={c.container}>
      {
        total > pageSize * currentPage && showLoadMore
          ? (
            <div className={c.loadMore}>
              <Button loading={isLoading} onClick={onLoadMore}>
                {showMoreTitle}
              </Button>
            </div>
          )
          : null
      }
      {
        showPages && total > pageSize ? (
          <AntPagination
            itemRender={itemRender(baseHref)}
            showSizeChanger={showSizeChanger}
            className={c.pagination}
            pageSize={pageSize}
            current={parseInt(currentPage, 10)}
            total={total}
            onChange={handlePageChange}
          />
        ) : null
      }
    </div>
  );
}

Pagination.propTypes = {
  total: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onLoadMore: PropTypes.func,
  onPageChange: PropTypes.func,
  currentPage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  itemsPerRow: PropTypes.number,
  showSizeChanger: PropTypes.bool,
  pageSize: PropTypes.number,
  showPages: PropTypes.bool,
  showLoadMore: PropTypes.bool,
  showMoreTitle: PropTypes.string,
  baseHref: PropTypes.string,
};

Pagination.defaultProps = {
  currentPage: 1,
  itemsPerRow: 4,
  showSizeChanger: false,
  pageSize: 24,
  showPages: true,
  showMoreTitle: 'Show More',
  showLoadMore: true,
  onPageChange: () => null,
  onLoadMore: () => null,
  baseHref: '',
};

export default Pagination;

export function getReleaseList(releaseIdList = [], releaseList = {}, artistList = {}) {
  return compose(
    map((release) => assoc('artist', compose(flip(prop)(artistList.data), prop('artist'))(release), release)),
    map(flip(prop)(releaseList.data))
  )(releaseIdList);
}

export function getPageSize() {
  return defaultPagination.rows * defaultPagination.maxCols;
}
