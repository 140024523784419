import React, {
  memo, useEffect, useMemo, useState,
} from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'antd';

import { getHomeRecommended } from 'actions/release';

import SheetTitle from '../../components/SheetTitle';
import ReleaseCard from '../../components/ReleaseCard';
import Pagination from '../../components/Pagination';
import { MAX_COLS, ROWS } from './common';
import c from './RecommendedReleases.less';

const RecommendedReleases = () => {
  const {
    recommendedReleaseIdList,
    releaseList,
    artistList,
    loggedIn,
    app,
    genresList,
  } = useSelector((store) => ({
    recommendedReleaseIdList: store.releases.homeRecommended,
    releaseList: store.releases.list,
    artistList: store.artistList,
    loggedIn: store.user.loggedIn,
    app: store.app,
    genresList: store.genres.data,
  }), shallowEqual);
  const [page, setPage] = useState(Math.ceil(recommendedReleaseIdList.length / (MAX_COLS * ROWS)) ?? 1);
  const dispatch = useDispatch();

  useEffect(() => {
    setPage(1);
  }, []);

  const recommendedReleases = useMemo(() => {
    return recommendedReleaseIdList
      .slice(0, page * MAX_COLS * ROWS)
      .map((id) => releaseList.data[id])
      .filter((release) => release)
      .map((release) => ({
        ...release,
        artist: artistList.data[release.artist.toString()],
        genres: release.genres.map((genreId) => genresList[genreId.toString()].name),
      }));
  }, [recommendedReleaseIdList, page]);

  const handleLoadMoreRecommended = () => setPage(page + 1);

  useEffect(() => {
    if (app.renderedComponent === 'Home') return;
    dispatch(getHomeRecommended());
  }, [loggedIn]);

  return (
    <>
      <SheetTitle title="Recommendations" />
      <Row
        gutter={{
          xs: 10, sm: 16, md: 20, lg: 20,
        }}
        className={c.releaseListContainer}
      >
        {
          recommendedReleases.map((release) => (
            <Col xs={12} sm={12} md={8} lg={6} key={release.id}>
              <ReleaseCard
                release={release}
                showGenre
                tracking={{ analytics: { category: 'Recommendation' } }}
              />
            </Col>
          ))
        }
      </Row>
      <Pagination
        isLoading={false}
        total={recommendedReleaseIdList.length}
        onLoadMore={handleLoadMoreRecommended}
        showPages={false}
        showMoreTitle="Show more"
        pageSize={MAX_COLS * ROWS}
        currentPage={page}
      />
    </>
  );
};

export default memo(RecommendedReleases);
