import React, { memo } from 'react';
import TruncateMarkup from 'react-truncate-markup';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { slugify } from 'helpers/view';

import c from './ReleaseCard.less';

const ArtistLink = ({ artist }) => {
  if (!artist?.name) return null;

  const artistLink = `/artist/${slugify(artist.name, artist.id)}`;

  return artist.id === 0 ? (
    <TruncateMarkup lines={1}><span className={c.artistName}>{artist?.name}</span></TruncateMarkup>
  ) : (
    <Link className={c.artistName} to={artistLink}>
      <TruncateMarkup lines={1}><span>{artist?.name}</span></TruncateMarkup>
    </Link>
  );
};

ArtistLink.propTypes = {
  artist: PropTypes.object.isRequired,
};

export default memo(ArtistLink);
